import React from 'react'
import { Link as RouterLink } from 'gatsby'

import Layout from 'components/Layout'
import SEO from 'components/Seo'
import { useSharedState } from 'context/sharedState'

import {
  Section,
  Flex,
  Box,
  Row,
  Column,
  Heading,
  Text,
  Button,
  Space,
} from 'components'

const NotFoundPage = () => {
  const [{ entryPath }] = useSharedState()

  return (
    <Layout>
      <SEO title="404 – Not found" />

      <Section py={0} pt={[6, 8]}>
        <Row>
          <Column size={[4 / 12]} height={600}></Column>
          <Column size={[8 / 12]}>
            <Box maxWidth={560} py={4} px={4}>
              <Space mb={2}>
                <Heading.H2 as="h1">404 - Seite nicht gefunden</Heading.H2>

                <Heading.H3 as="h2">Es tut uns leid...</Heading.H3>

                <Text>
                  ...Sie wollten sich diese Seite ansehen und wir stehen mit
                  leeren Händen da. Soetwas gehört sich nicht, das wissen wir.
                </Text>

                <Flex mt={4} py={4}>
                  <Space mr={4}>
                    <Button as={RouterLink} to={entryPath || '/'}>
                      Zur Startseite
                    </Button>
                  </Space>
                </Flex>
              </Space>
            </Box>
          </Column>
        </Row>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
